import * as React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import styled from 'styled-components';

const Wrapper = styled.div`
  min-height: 100vh;
  // background: linear-gradient(to bottom, #340200 0%, #881514 50%, #d65d10 100%);
  background-image: url('/insideout.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
`;

const Body = styled.div``;

type OwnProps = {
  children?: React.ReactNode;
};

const Page: React.FC<OwnProps> = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <Body>{children}</Body>
      <Footer />
    </Wrapper>
  );
};

export default Page;
