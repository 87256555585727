import { RootState } from '../../configureStore';
import { SubmissionError, getFormValues, reset } from 'redux-form';
import { authUserSelector } from '../App/selectors';
import { connect } from 'react-redux';
import { getProp } from 'sportnet-utilities';
import { useAsyncData } from 'ssr-service';
import Api from '../../api/Api';
import Cookies from 'js-cookie';
import CoreApi, { AppSpaceUser, OrganizationProfile } from '../../api/CoreApi';
import FormField from '@sportnet/ui/FormField';
import Loader from '../../components/Loader';
import Message from '@sportnet/ui/Message';
import Modal, { ModalContent } from '@sportnet/ui/Modal';
import React from 'react';
import RequestForm, { formName } from './form';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import __ from '../../utilities/__';
import config from '../../config';

const RequestFormWrapper: React.FC<any> = ({
  authUser,
  dispatch,
  formValues,
}) => {
  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [organizationEmail, setOrganizationEmail] = React.useState('');
  const [formInitialValues, setFormInitialValues] = React.useState<{
    [key: string]: any;
  }>({});
  const [isBusinessInfoFetching, setIsBusinessInfoFetching] =
    React.useState(false);
  const [appSpacesAreFetching, setAppSpacesAreFetching] = React.useState(false);
  const [isFetchingAppSpace, setIsFetchingAppSpace] = React.useState(false);
  const [isAppSpaceModalOpened, setIsAppSpaceModalOpened] =
    React.useState(false);
  const [appSpaceModalItems, setAppSpaceModalItems] = React.useState<
    OrganizationProfile[]
  >([]);
  const [appSpaces, setAppSpaces] = React.useState<AppSpaceUser[]>([]);
  const [bankAccounts, setBankAccounts] = React.useState([]);
  const [addresses, setAddresses] = React.useState([]);
  const [activeAppSpace, setActiveAppspace] = React.useState({
    value: '',
    label: __('Nová registrácia'),
  });
  const [isSportExpertFetching, setIsSportExpertFetching] = React.useState<{
    [key: number]: boolean;
  }>({
    1: false,
    2: false,
  });

  useAsyncData(async () => {
    getAppSpaces();
  }, []);

  const toggleAppSpaceModal = () => {
    setIsAppSpaceModalOpened(!isAppSpaceModalOpened);
  };

  const getAppSpaces = async () => {
    try {
      setAppSpacesAreFetching(true);
      const { appspaces = [] } = await CoreApi.meAppSpacesForApp('CRM');
      setAppSpaces(appspaces);
    } catch (e: any) {
      //
    } finally {
      setAppSpacesAreFetching(false);
    }
  };

  const loadBusinessInfo = async () => {
    const businessId: any = getProp(
      formValues,
      ['organization', 'business_id'],
      '',
    );
    if (!businessId) {
      return;
    }
    setIsBusinessInfoFetching(true);
    try {
      const response = await CoreApi.getBusinessInfo(
        businessId.replace(/\s/g, ''),
      );
      return response || {};
    } catch (e: any) {
      window.alert(__('Podľa zadaného IČO nebolo možné nájsť žiadny subjekt')); // eslint-disable-line
      dispatch(reset(formName));
      return null;
    } finally {
      setIsBusinessInfoFetching(false);
    }
  };

  const loadSportExpert = async (idx: number) => {
    setIsSportExpertFetching({
      [idx]: true,
    });
    try {
      const userId = getProp(formValues, [`sport_expert_${idx}`, '._id'], '');
      if (!userId) {
        return {};
      }
      const res = await CoreApi.getPublicUserProfile(userId);
      return res || {};
    } catch (e: any) {
      window.alert(
        __('Podľa zadaného sportnet ID nebolo možné nájsť žiadneho užívateľa'),
      ); // eslint-disable-line
      setFormInitialValues({
        ...formValues,
        [`sport_expert_${idx}`]: {
          _id: formValues[`sport_expert_${idx}`]._id,
        },
      });
      return null;
    } finally {
      setIsSportExpertFetching({
        [idx]: false,
      });
    }
  };

  const handleGetSportExpert = async (idx: number) => {
    const user: any = await loadSportExpert(idx);
    if (user) {
      const modifiedFormInitialValues = {
        ...formValues,
        [`sport_expert_${idx}`]: {
          _id: user._id,
          display_name: `${getProp(user, ['titles', 'before'], '')} ${
            user.name || ''
          } ${user.surname || ''} ${getProp(
            user,
            ['titles', 'after'],
            '',
          )}`.trim(),
        },
      };
      setFormInitialValues(modifiedFormInitialValues);
    }
  };

  const handleGetBusinessInfo = async () => {
    const businessInfo: any = await loadBusinessInfo();
    if (!businessInfo) {
      return;
    }
    const org = businessInfo.organization || {};
    const { _id: addressId, ...address } = (org.addresses || [])[0] || {};
    // format initial values
    const {
      name,
      full_name,
      // email,
      phone,
      vat_nr,
      dic,
      business_id,
    } = org;

    const modifiedFormInitialValues = {
      ...formValues,
      organization: {
        ...(formValues.organization || {}),
        name,
        full_name,
        // email,
        phone,
        vat_nr: vat_nr || dic,
        business_id,
      },
      address: {
        ...(formValues.address || {}),
        INVOICE: address,
      },
    };

    // sportnet org by business id
    if (businessInfo.organization.source === 'sportnet') {
      const sportnetBusinessInfo = await CoreApi.getOrganizations({
        businessId: business_id,
      });
      if (sportnetBusinessInfo && sportnetBusinessInfo.organizations) {
        const { _id: orgId }: any = sportnetBusinessInfo.organizations[0];
        const { items: organizationProfiles } =
          await CoreApi.getOrganizationProfiles(orgId);
        if ((organizationProfiles || []).length) {
          setIsBusinessInfoFetching(false);
          setIsAppSpaceModalOpened(true);
          setAppSpaceModalItems(organizationProfiles || []);
          setFormInitialValues({
            organization: {
              name: org.name,
              full_name: org.full_name,
              // email: org.email,
              phone: org.phone,
              vat_nr: org.vat_nr || org.dic,
              legal_form: org.legal_form,
              business_id,
            },
          });
        }
      }
    } else {
      setIsBusinessInfoFetching(false);
      setFormInitialValues(modifiedFormInitialValues);
    }
  };

  const changeAppSpace = async (i: any) => {
    setActiveAppspace(
      i && i.value
        ? i
        : {
            value: '',
            label: __('Nová registrácia'),
          },
    );
    setBankAccounts([]);
    setAddresses([]);
    setFormInitialValues({
      ...formInitialValues,
      ...(i && i.value
        ? { appSpace: i.value, profile: { name: i.label } }
        : { appSpace: '', profile: {}, organization: {} }),
    });
    setIsFetchingAppSpace(true);
    if (i && i.value) {
      try {
        const { items: fetchedBankAccounts } =
          await CoreApi.crmMyOrganizationBankAccounts(i.value);
        const { items: fetchedAddresses } =
          await CoreApi.crmMyOrganizationAddresses(i.value);
        const { contact_person, email, ...organization } =
          await CoreApi.crmMyOrganization(i.value);

        setBankAccounts(fetchedBankAccounts as any);
        setAddresses(fetchedAddresses as any);
        setFormInitialValues({
          ...formInitialValues,
          appSpace: i.value,
          profile: { name: i.label },
          organization,
        });
      } catch (e: any) {
        //
      } finally {
        setIsFetchingAppSpace(false);
      }
    } else {
      setIsFetchingAppSpace(false);
    }
  };

  const changeModalAppSpace = async (i: any) => {
    if (i && i.value && i.value !== 'new') {
      try {
        setIsFetchingAppSpace(true);
        const { items: fetchedBankAccounts } =
          await CoreApi.ppoOrganizationBankAccounts(i.value);
        const { items: fetchedAddresses } =
          await CoreApi.ppoOrganizationAddresses(i.value);
        setActiveAppspace(i);
        setBankAccounts(fetchedBankAccounts as any);
        setAddresses(fetchedAddresses as any);
        setFormInitialValues({
          ...formInitialValues,
          appSpace: i.value,
          profile: { name: i.label },
        });
      } catch (e: any) {
        //
      } finally {
        setIsFetchingAppSpace(false);
      }
    } else {
      setActiveAppspace({ ...i, value: '' });
      setFormInitialValues({
        ...formInitialValues,
        appSpace: '',
        profile: {},
      });
    }
    toggleAppSpaceModal();
  };

  const submit = async (values: any) => {
    if ((values.users || []).length === 0) {
      alert(
        __(
          'Pridajte prosím oprávnené osoby zodpovedné za bezpečnosť detí počas realizácie pohybovo-športových aktivít so zameraním na futbal.',
        ),
      );
      throw new SubmissionError({});
    }
    let transformedValues: any = {};
    if (values.appSpace) {
      transformedValues = {
        ...values,
        appSpace: values.appSpace,
        users: values.users.map((u: any) => ({
          ...u,
          license_type: u.license_type.value,
          shirt_size: u.shirt_size.value,
        })),
        organization: {
          ...values.organization,
          legal_form: getProp(
            values,
            ['organization', 'legal_form', 'value'],
            values.organization.legal_form,
          ),
        },
        addresses: Object.keys(values.address).reduce((acc, type) => {
          if (type.includes('DRAFT')) {
            return acc;
          }
          return [
            ...acc,
            {
              ...values.address[type],
              type,
              country:
                values.address[type].country.value ||
                values.address[type].country,
            },
          ];
        }, []),
      };

      const address = addresses.find(
        (a: any) => a._id === values.address.INVOICE,
      );
      transformedValues.address = address;

      const bankAccount = bankAccounts.find(
        (i: any) => i._id === values.bank_account.IBAN,
      );
      transformedValues.bank_account = bankAccount;

      transformedValues.profile = {
        ...values.profile,
        type: values.profile.type.value,
      };

      const { months } = values.targetGroup[Number(values.targetGroups)];
      transformedValues.targetGroups = [];
      transformedValues.targetGroups[Number(values.targetGroups)] = {
        targetGroupIdx: Number(values.targetGroups),
        months: Object.keys(months || {}).reduce((acc, month) => {
          if (months[month]) {
            return [...acc, month];
          }
          return acc;
        }, []),
      };

      // transformedValues.targetGroups = values.targetGroups.map(
      //   (tg: any, idx: number) => {
      //     if (!tg) {
      //       return null;
      //     }
      //     const { iban, months } = values.targetGroup[idx];
      //     const targetGroupBankAccount = bankAccounts.find(
      //       (i: any) => i._id === iban,
      //     );
      //     return {
      //       targetGroupIdx: idx,
      //       bank_account: targetGroupBankAccount,
      //       months: Object.keys(months || {}).reduce((acc, month) => {
      //         if (months[month]) {
      //           return [...acc, month];
      //         }
      //         return acc;
      //       }, []),
      //     };
      //   },
      // );
    } else {
      transformedValues = {
        ...values,
        users: values.users.map((u: any) => ({
          ...u,
          license_type: u.license_type.value,
          shirt_size: u.shirt_size.value,
        })),
        organization: {
          ...values.organization,
          legal_form: getProp(
            values,
            ['organization', 'legal_form', 'value'],
            values.organization.legal_form,
          ),
        },
        address: {
          ...values.address.INVOICE,
          country:
            values.address.INVOICE.country.value ||
            values.address.INVOICE.country,
        },
        bank_account: values.bank_account,
        addresses: Object.keys(values.address).map((type) => ({
          ...values.address[type],
          type,
          country:
            values.address[type].country.value || values.address[type].country,
        })),
      };

      transformedValues.profile = {
        ...values.profile,
        type: values.profile.type.value,
      };

      const { months } = values.targetGroup[Number(values.targetGroups)];
      transformedValues.targetGroups = [];
      transformedValues.targetGroups[Number(values.targetGroups)] = {
        targetGroupIdx: Number(values.targetGroups),
        months: Object.keys(months || {}).reduce((acc, month) => {
          if (months[month]) {
            return [...acc, month];
          }
          return acc;
        }, []),
      };

      // transformedValues.targetGroups = values.targetGroups.map(
      //   (i: any, idx: number) => {
      //     if (!i) {
      //       return null;
      //     }
      //     const { iban, months } = values.targetGroup[idx];
      //     return {
      //       targetGroupIdx: idx,
      //       bank_account: { IBAN: iban },
      //       months: Object.keys(months || {}).reduce((acc, month) => {
      //         if (months[month]) {
      //           return [...acc, month];
      //         }
      //         return acc;
      //       }, []),
      //     };
      //   },
      // );
    }

    delete transformedValues.organization._id;
    delete transformedValues.organization.logo_public_url;
    delete transformedValues.organization.favicon_public_url;

    const data = {
      user: {
        name: authUser.name,
        surname: authUser.surname,
        email: authUser.email,
        phone: authUser.phone,
      },
      data: {
        po: transformedValues,
        source: process.env.REACT_APP_APP_ID || '',
      },
      source: process.env.REACT_APP_APP_ID,
    };

    try {
      setSubmitting(true);
      const token = Cookies.get('access_token');
      Api.setToken(token || '');
      await Api.publicCreatePoRegistryRequest(
        config.PROJECT_NAME,
        {},
        { data },
      );
      setSubmitted(true);
      setSubmitting(false);
      setOrganizationEmail(transformedValues.organization.email);
    } catch (e: any) {
      alert(
        `${__('Registrácia zlyhala. Chybová hláška:')} ${JSON.stringify(
          e.details,
        )}`,
      );
      setSubmitting(false);
    }
  };

  if (submitted === true) {
    return (
      <Segment raised>
        <ul>
          <li>
            {__(
              'Dobrý deň, ďakujeme za zaslanie žiadosti o registráciu MŠ / ZŠ / ZŠ s MŠ do projektu Disney Playmakers.',
            )}
          </li>
          <li>
            {__(
              'Prvý email Vám príde v krátkom čase po zaevidovaní „žiadosti o registráciu“ na emailovú adresu, ktorú ste poskytli v žiadosti',
            )}{' '}
            ({organizationEmail})
          </li>
          <li>
            {__(
              'V prípade, že Vám nepríde po odoslaní „žiadosti o registráciu“ email s informáciami, skúste najskôr skontrolovať Vašu schránku nevyžiadaných správ (priečinok SPAM). V prípade, ak Vám nepríde žiadny email na adresu,  ktorú ste poskytli v žiadosti',
            )}{' '}
            ({organizationEmail}){' '}
            {__(
              'kontaktujte nás na tel. čísle 0918 900 425 alebo na email dajmespolugol@futbalsfz.sk',
            )}
            .
          </li>
          <li>
            {__(
              'Druhý email Vám príde vtedy, keď je Vaša žiadosť správne vyplnená, na Vami poskytnutú emailovú adresu v žiadosti',
            )}{' '}
            ({organizationEmail}){' '}
            {__(
              'kde Vám v prílohe pošleme zmluvu medzi štatutárom MŠ / ZŠ s MŠ / ZŠ a Slovenským futbalovým zväzom a všeobecné zmluvné podmienky, prípadne dodatok k zmluve, ak ste sa už v minulosti projektu zúčastnili.',
            )}
          </li>
          <li>
            {__(
              'Ak je Vaša žiadosť nesprávne vyplnená, na Vami poskytnutú emailovú adresu v žiadosti:',
            )}{' '}
            ({organizationEmail}){' '}
            {__('Vám pošleme dôvod zamietnutia žiadosti.')}
          </li>
        </ul>
      </Segment>
    );
  } else {
    if (appSpacesAreFetching) {
      return <Loader />;
    }
    return (
      <React.Fragment>
        {appSpaces.length > 0 && (
          <Segment
            raised
            header={
              <SegmentHeader size="s" withSeparator>
                {__('Typ registrácie')}
              </SegmentHeader>
            }
          >
            <Message warning>
              {__(
                'V prípade, že ste sa už minulý rok zúčastnili projektu "Disney Playmakers" a želáte si predĺžiť zmluvu na ďalší školský rok, vyberte zo zoznamu Vašu školu.',
              )}
            </Message>
            <FormField
              type="theselect"
              clearable={false}
              options={[
                {
                  value: '',
                  label: __('Nová registrácia'),
                },
                ...appSpaces.map((i: any) => ({
                  label: i.org_profile.name,
                  value: i.org_profile._id,
                })),
              ]}
              value={activeAppSpace}
              onChange={changeAppSpace}
            />
          </Segment>
        )}
        {isFetchingAppSpace ? (
          <Loader />
        ) : (
          <RequestForm
            onSubmit={submit}
            isFetching={submitting}
            initialValues={formInitialValues}
            isBusinessInfoFetching={isBusinessInfoFetching}
            isSportExpertFetching={isSportExpertFetching}
            onGetBusinessInfo={handleGetBusinessInfo}
            onGetSportExpert={handleGetSportExpert}
            bankAccounts={bankAccounts}
            addresses={addresses}
          />
        )}
        <Modal
          isOpen={isAppSpaceModalOpened}
          handleClose={() => {
            // zavriet sa da len vyberom hodnoty
          }}
        >
          <ModalContent>
            <FormField
              label={__('Profil organizácie')}
              type="theselect"
              onChange={changeModalAppSpace}
              placeholder={__('Vyberte zo zoznamu')}
              value={activeAppSpace.value ? activeAppSpace : null}
              clearable={false}
              options={[
                { _id: 'new', name: __('Nová registrácia') },
                ...appSpaceModalItems,
              ].map((i) => ({
                label: i.name,
                value: i._id,
              }))}
            />
          </ModalContent>
        </Modal>
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state: RootState) => {
  return {
    formValues: getFormValues(formName)(state),
    authUser: authUserSelector(state),
  };
};

export default connect(mapStateToProps)(RequestFormWrapper as any);
