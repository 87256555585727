import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import { commit, getListTotal, isCommiting } from '@sportnet/redux-list/ducks';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import { useAsyncData } from 'ssr-service';
import Api from '../../api/Api';
import EntryAnimation from '../../components/EntryAnimation';
import Lightbox, { ImagesListType } from 'react-spring-lightbox';
import Modal, { ModalContent } from '@sportnet/ui/Modal';
import Page from '../../containers/Page';
import __ from '../../utilities/__';
import config from '../../config';
import styled from 'styled-components';
import useQuery, { StringParam } from '@sportnet/query-hoc/useQuery';
// import getData from './data';
import { listEventsSelector } from './selectors';
import MediaManagerImage from '@sportnet/ui/MediaManagerImage';
import RedirectToRegistration from './redirectToRegistration';

const images: ImagesListType = [
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/1.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 1',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/2.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 2',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/3.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 3',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/4.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 4',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/5.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 5',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/6.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 6',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/7.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 7',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/8.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 8',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/9.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 9',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/10.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 10',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/11.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 11',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/12.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 12',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/13.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 13',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/14.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 14',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/15.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 15',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/16.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 16',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/17.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 17',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/18.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 18',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/19.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 19',
  },
  {
    src: 'https://mediamanager.sportnet.online/images/1024x0/pages/d/disney/2022/08/20.jpeg',
    loading: 'lazy',
    alt: 'Playmakers 20',
  },
];

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  // background: ${({ theme }) => theme.color.primary};
  background: transparent;
  // min-height: 100vh;
`;

const Content = styled.div`
  margin: auto;
  padding: ${rem(4)} ${rem(16)};
  max-width: ${rem(config.CONTENT_MAX_WIDTH)};
`;

const ContentWrapper = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.16);
  border-radius: ${rem(4)};
  ${mb('m')} {
    flex-direction: row;
  }
  background: #fff;
  padding: ${rem(8)};
  > div {
    width: 100%;
    ${mb('m')} {
      width: 50%;
    }
  }
`;

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PhotoGalleryThumbnail = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: calc(50% - ${rem(16)});
  height: ${rem(150)};
  box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.16);
  border-radius: ${rem(4)};
  margin: ${rem(8)};
  padding: ${rem(8)};
  background: #fff;
  overflow: hidden;
  cursor: pointer;
  img {
    object-fit: contain;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const InnerPhotoGalleryWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const InnerPhotoGalleryThumbnail = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: ${rem(50)};
  height: ${rem(50)};
  box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.16);
  border-radius: ${rem(4)};
  margin: ${rem(8)};
  padding: ${rem(8)};
  background: #fff;
  overflow: hidden;
  cursor: pointer;
  img {
    object-fit: contain;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ThumbnailOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #333;
  background: rgba(255, 255, 255, 0.75);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${rem(13)};
  font-weight: 600;
`;

const ProjectInfo = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;

const ProjectInfoHeader = styled.div`
  font-family: 'Bangers', cursive;
  font-size: 62px;
`;

const ProjectInfoContent = styled.div`
  padding-top: 32px;
  font-size: 18px;
  text-align: justify;
  > b {
    font-weight: 600;
  }
`;

const IntroWrapper = styled.div`
  position: relative;
  width: 100%;
  background: transparent;
  z-index: 0;
  ${mb('m')} {
    background: transparent;
    :after {
      content: '';
      // background: url('/uefa-Playmakers.jpg');
      opacity: 0.2;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 100%;
    }
  }
  // height: calc(100vh - ${rem(config.HEADER_HEIGHT)});
  // min-height: ${rem(350)};
`;

const DesktopBanner = styled.img``;
const MobileBanner = styled.img``;

const Intro = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
  flex-direction: column;
  ${mb('m')} {
    flex-direction: row;
  }
  > ${DesktopBanner} {
    width: 60%;
    padding: ${rem(64)} ${rem(64)} ${rem(64)} 0;
    display: none;
    ${mb('m')} {
      display: block;
    }
  }
  > ${MobileBanner} {
    width: 100%;
    display: block;
    ${mb('m')} {
      display: none;
    }
  }
`;

const IntroActions = styled.div`
  width: 100%;
  ${mb('m')} {
    position: absolute;
    width: 40% !important;
    background: rgb(255 255 255 / 30%);
    border-radius: ${rem(4)};
    padding: ${rem(32)};
    right: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
  }
  button {
    width: 100%;
    font-size: ${rem(13)};
    margin: ${rem(4)} 0;
    ${mb('m')} {
      width: auto;
      margin: 0px ${rem(4)} 0px 0px;
    }
  }
`;

const MapWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin-bottom: ${rem(16)};
  ${mb('m')} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

// const MultiDownloadWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   background: ${({ theme }) => theme.color.primary};
//   padding: 16px 24px;
//   color: white;
//   border-radius: 4px;
//   margin-bottom: ${rem(8)};
//   a {
//     font-weight: 600;
//     text-decoration: underline;
//     color: white;
//     > div {
//       display: flex;
//       align-items: center;
//       > div {
//         padding-left: 24px;
//       }
//     }
//   }
// `;

// const DownloadWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   background: rgb(233, 65, 65);
//   padding: 16px 24px;
//   color: white;
//   border-radius: 4px;
//   font-weight: 600;
//   text-decoration: underline;
//   > div {
//     padding-left: 24px;
//   }
// `;

// const IncredibleLeft = styled.img`
//   position: absolute;
//   top: 0;
//   max-height: 400px;
//   opacity: 1;
//   left: 0;
//   transform: translate(-100%, 25%);
//   max-height: 300px;
// `;

// const IncredibleRight = styled.img`
//   position: absolute;
//   top: 0;
//   max-height: 400px;
//   opacity: 1;
//   right: -400px;
//   // transform: translate(100%, 0%) rotateY(180deg);
// `;

const MoreAboutProject = styled.div`
  font-size: ${rem(16)};
  width: 100%;
  button {
    background: #ff3c3c;
    color: #fff;
    padding: ${rem(8)} ${rem(16)};
    border-width: 0;
    border-radius: ${rem(4)};
    box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.16);
    font-weight: 700;
    font-family: 'Open Sans';
    cursor: pointer;
    font-size: 14px;
    width: 100%;
    letter-spacing: -1px;
  }
`;

const IntroActionsText = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding: 24px 0;
`;

// const EventWrapper = styled.div`
//   cursor: pointer;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   :hover {
//     background: #eee;
//   }
// `;

// const Events = styled.div`
//   padding-top: ${rem(24)};
//   padding-bottom: ${rem(8)};
//   ${EventWrapper} {
//     padding: ${rem(8)};
//     border-bottom: ${rem(1)} solid #eee;
//     :last-child {
//       border-width: 0;
//     }
//   }
// `;

// const EventSportGround = styled.div`
//   font-weight: 600;
//   padding: ${rem(8)};
// `;

type OwnProps = {};

const mapStateToProps = (state: RootState) => ({
  events: listEventsSelector(state),
  total: getListTotal(config.HOME_EVENTS_LIST_NAME)(state),
  isFetching: isCommiting(config.HOME_EVENTS_LIST_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  setList: initializeOrSetListParams.action,
  commitList: commit.action,
  updateEvents: updateEntities,
};

type IMapDispatchToProps = typeof mapDispatchToProps;

type Props = RouteProps<{}> &
  OwnProps &
  IMapStateToProps &
  ReduxConnectProps &
  IMapDispatchToProps;

const title = __('Hlavná stránka');

const Home: React.FC<Props> = ({
  setList,
  commitList,
  updateEvents,
  events,
  location: { search, pathname },
  router,
}) => {
  const [activeDate, setActiveDate] = React.useState(new Date());

  const [isLightboxOpened, setIsLightboxOpened] = React.useState(false);
  const [currentImageIndex, setCurrentIndex] = React.useState(0);

  const toggleLightbox = (index = 0) => {
    setCurrentIndex(index);
    setIsLightboxOpened(!isLightboxOpened);
  };

  const gotoPrevious = () =>
    currentImageIndex > 0
      ? setCurrentIndex(currentImageIndex - 1)
      : setCurrentIndex(images.length - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length
      ? setCurrentIndex(currentImageIndex + 1)
      : setCurrentIndex(0);

  const [modalOpened, setModalOpened] = React.useState(false);

  const [Leaflet, setLeaflet] = React.useState<any>(null);

  React.useEffect(() => {
    if (window) {
      const reactLeaflet = require('react-leaflet');
      const leaflet = require('leaflet');

      setLeaflet({
        Map: reactLeaflet.Map,
        Marker: reactLeaflet.Marker,
        TileLayer: reactLeaflet.TileLayer,
        Popup: reactLeaflet.Popup,
        FeatureGroup: leaflet.FeatureGroup,
        LeafletMarker: leaflet.Marker,
      });
    }
  }, []);

  const { query } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    {
      parameters: {
        eventsDate: StringParam(''),
      },
    },
  );

  const normalizeEvents = (data: any[]) => {
    return {
      entities: {
        events: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };

  useAsyncData(() => {
    if (query.eventsDate) {
      setActiveDate(new Date(query.eventsDate as string));
    }
  }, [query.eventsDate]);

  const toggleModal = () => setModalOpened(!modalOpened);

  useAsyncData(async () => {
    setList({
      listName: config.HOME_EVENTS_LIST_NAME,
      params: {
        activeDate: format(activeDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      },
    });
    await commitList({
      listName: config.HOME_EVENTS_LIST_NAME,
      load: async () => {
        try {
          const res = await Api.publicGetEvents(config.PROJECT_NAME, {
            noLimit: true,
            dateFrom: format(
              startOfMonth(activeDate),
              "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
            ),
            dateTo: format(
              endOfMonth(activeDate),
              "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
            ),
          });
          const { result, entities } = normalizeEvents(res.events);

          updateEvents(entities);
          return {
            results: result,
            total: res.total,
          };
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam športových udalostí'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  }, [activeDate]);

  // let markers = [];
  // let featureGroup;
  //  let bounds;

  if (Leaflet && Leaflet.LeafletMarker) {
    // markers = getData().map(item => {
    //   return new Leaflet.LeafletMarker([
    //     item.addresses[0].lat,
    //     item.addresses[0].lon,
    //   ]);
    // });
    // featureGroup = new Leaflet.FeatureGroup(markers as any);
    // bounds = featureGroup.getBounds();
  }

  return (
    <EntryAnimation key={'home'}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="section" />
      </Helmet>
      <Lightbox
        isOpen={isLightboxOpened}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={images}
        currentIndex={currentImageIndex}
        style={{ background: 'rgba(0, 0, 0, 0.75)' }}
        onClose={() => toggleLightbox()}
        singleClickToZoom
        renderFooter={() => {
          return (
            <InnerPhotoGalleryWrapper>
              {images.map((image, idx) => (
                <InnerPhotoGalleryThumbnail
                  onClick={() => {
                    setCurrentIndex(idx);
                  }}
                >
                  <MediaManagerImage
                    key={`picture_${image}`}
                    picture={{ media_url: image.src }}
                    naturalWidth={40}
                    naturalHeight={40}
                    alt={image.alt}
                    script="media"
                  />
                </InnerPhotoGalleryThumbnail>
              ))}
            </InnerPhotoGalleryWrapper>
          );
        }}
        /* react-spring config for open/close animation */
        pageTransitionConfig={{
          from: { transform: 'scale(0.75)', opacity: 0 },
          enter: { transform: 'scale(1)', opacity: 1 },
          leave: { transform: 'scale(0.75)', opacity: 0 },
          config: { mass: 1, tension: 320, friction: 32 },
        }}
      />
      <Page>
        {modalOpened && (
          <Modal isOpen={modalOpened} handleClose={toggleModal}>
            <ModalContent>
              <RedirectToRegistration handleClose={toggleModal} />
            </ModalContent>
          </Modal>
        )}
        <Wrapper>
          <IntroWrapper>
            <Content>
              <Intro>
                <DesktopBanner src="/main-logo-dark.svg" />
                <MobileBanner src="/uefa-playmakers-white.jpg" />
                <IntroActions>
                  <MoreAboutProject>
                    <div>
                      <div className="sentence">
                        &nbsp;
                        <div className="fadeIn">
                          <span>Športová aktivita</span>
                          <span>Tímová hra</span>
                          <span>Nekonečná zábava</span>
                        </div>
                      </div>
                      <div className="sentence">ako žiadna iná!</div>
                      <IntroActionsText>
                        Objavte čaro futbalu prostredníctvom pohybu, hier a
                        kúzla Disney rozprávok.
                      </IntroActionsText>
                      <Link to="/prihlas-sa">
                        <button>{__('Prihlás sa do projektu!')}</button>
                      </Link>
                    </div>
                  </MoreAboutProject>
                </IntroActions>
              </Intro>
            </Content>
          </IntroWrapper>
          {/* <Content>
            <MultiDownloadWrapper>
              <div style={{ marginBottom: rem(8) }}>
                Nižšie v prílohách nájdete certifikáty pre deti zapojené do
                projektu Dajme spolu gól.
              </div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mediamanager.sportnet.online/media/pages/d/dajmespolugol/2021/06/dsg-certifikat-za-ucast_chlapec.pdf"
              >
                <div>
                  <Icon size={24} color="#fff" name="file-download" />
                  <div>Certifikát pre chlapca</div>
                </div>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mediamanager.sportnet.online/media/pages/d/dajmespolugol/2021/06/dsg-certifikat-za-ucast_dievca.pdf"
              >
                <div>
                  <Icon size={24} color="#fff" name="file-download" />
                  <div>Certifikát pre dievča</div>
                </div>
              </a>
            </MultiDownloadWrapper>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://mediamanager.sportnet.online/media/pages/d/dajmespolugol/2021/06/informacie-o-ukonceni-sk.-roka-20202021-+-informacie-o-pokracovani-v-novom-rocniku-20212022.pdf"
            >
              <DownloadWrapper>
                <Icon size={24} color="#fff" name="file-download" />
                <div>
                  Aktualizované dňa 30.6.2021: Informácie k ukončeniu projektu v
                  školskom roku 2020/21
                </div>
              </DownloadWrapper>
            </a>
          </Content> */}
          <Content>
            <MapWrapper>
              {/* <IncredibleLeft src="/Dash.png" /> */}
              {!!Leaflet && (
                // <Leaflet.Map
                //   {...(markers.length && { bounds })}
                //   // bounds={bounds}
                //   style={{
                //     height: rem(400),
                //     width: '100%',
                //     boxShadow: `0 ${rem(16)} ${rem(
                //       40,
                //     )} rgba(0,0,0,0.30), 0 ${rem(16)} ${rem(
                //       12,
                //     )} rgba(0,0,0,0.22)`,
                //   }}
                //   center={[48.756117, 19.417416]}
                //   zoom={8}
                //   animate={false}
                // >
                //   <Leaflet.TileLayer
                //     url="https://api.maptiler.com/maps/pastel/{z}/{x}/{y}.png?key=ODPpnBZ9YFjgcQZMUd86"
                //     attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                //     tileSize={512}
                //     zoomOffset={-1}
                //   />
                //   {getData().map(item => {
                //     return (
                //       <Leaflet.Marker
                //         onClick={() => {
                //           router.push(`/centrum/${item.appSpace}`);
                //         }}
                //         key={item.appSpace}
                //         position={[
                //           item.addresses[0].lat,
                //           item.addresses[0].lon,
                //         ]}
                //       >
                //         <Leaflet.Popup minWidth={250}>
                //           <Events>
                //             <EventSportGround>{item.name}</EventSportGround>
                //           </Events>
                //         </Leaflet.Popup>
                //       </Leaflet.Marker>
                //     );
                //   })}
                // </Leaflet.Map>
                <></>
              )}
              <ContentWrapper>
                <GalleryWrapper>
                  {images.slice(0, 4).map((image, idx) => {
                    return (
                      <PhotoGalleryThumbnail
                        onClick={() => {
                          toggleLightbox(idx);
                        }}
                      >
                        <MediaManagerImage
                          key={`picture_${image}`}
                          picture={{ media_url: image.src }}
                          naturalWidth={120}
                          naturalHeight={120}
                          alt={image.alt}
                          script="media"
                        />
                        {idx === 3 && images.length > 4 && (
                          <ThumbnailOverlay>
                            <div>
                              {__('Zobraziť fotogalériu')} (+{images.length - 3}
                              )
                            </div>
                          </ThumbnailOverlay>
                        )}
                      </PhotoGalleryThumbnail>
                    );
                  })}
                </GalleryWrapper>
                <ProjectInfo>
                  <ProjectInfoHeader>
                    {__('Pripravená hrať')}?
                  </ProjectInfoHeader>
                  <ProjectInfoContent>
                    <b>UEFA Playmakers</b>{' '}
                    {__(
                      'je športová aktivita ako žiadna iná, ktorá so sebou prináša',
                    )}{' '}
                    <b>
                      {__(
                        'zábavu a jedinečný úvod do hrania futbalu pre 5-8 ročné dievčatá',
                      )}
                    </b>
                    , {__('prostredníctvom pohybu, hier a')}{' '}
                    <b>{__('kúzla Disney')}</b> {__('rozprávok')}.
                  </ProjectInfoContent>
                </ProjectInfo>
              </ContentWrapper>
              {/* <IncredibleRight src="/moana.png" /> */}
            </MapWrapper>
          </Content>
        </Wrapper>
      </Page>
    </EntryAnimation>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Home);
